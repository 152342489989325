<script setup lang="ts">
import { AxiosError } from 'axios'
import get from 'lodash/get'
import { useDialog } from 'primevue/usedialog'
import { RestaurantAreas } from '~/lib/interfaces/restaurant'
import { Address } from '~/lib/services/store/order/order.dto'
import { getLatestAddresses } from '~/utils/restaurantUtils'

const authStore = useAuthStore()
const restaurantStore = useRestaurantStore()
const restaurantActions = useRestaurantActions()
const countryCode = useCountry()
const { trackNoRider, trackNoCoverage, trackConfirmAddress, trackSelectAddress } = useGTM()
const { restaurantServiceRef } = useStoreService()
const restaurantService = restaurantServiceRef()
const dialog = useDialog()
const { t } = useLocale()

const latestAddresses = authStore.userMcId
  ? getLatestAddresses(authStore.userMcId, countryCode)
  : []

const selectedAddress = ref<Address>()

const addressStr = (addrs: Address) => {
  const { address, number } = addrs
  return `${address}${number ? ' ' + number : ''}`
}

const cityStr = (addrs: Address) => {
  const { city, neighborhood } = addrs
  const groups = [neighborhood || '', city].filter(g => g)
  return groups.join(', ')
}

const selectAddress = async (addrs: Address) => {
  try {
    if (!addrs.latitude || !addrs.longitude) {
      restaurantStore.closeModal()
      return
    }
    trackConfirmAddress()
    const restaurant = await restaurantService.getRestaurantDelivery({
      latitude: String(addrs.latitude),
      longitude: String(addrs.longitude),
      countryCode,
      area: RestaurantAreas.DLV,
    })

    restaurantStore.restaurantTips = restaurant.tips
    restaurantActions.setRestaurant(restaurant)
    if (restaurantStore.selectedRestaurant)
      restaurantStore.selectedRestaurant.deliveryAddress = addrs
    restaurantStore.addAddressDLV()
    // Get if order will be delivered by walking crew
    restaurantActions.setCrewStatus(restaurant?.isCrew)
    restaurantActions.setDeliveryDistance(restaurant.distance)
  } catch (err: unknown) {
    if (err instanceof AxiosError) {
      const status = get(err, 'response.status', 500)
      // TODO: Change this to allow user to select restaurant for errors 613 and 618, when the backend updates the endpoint
      let alertKey = 'genericError'
      switch (status) {
        case 605:
          trackNoCoverage()
          alertKey = 'mcDeliveryError'
          break
        case 613:
          trackNoRider()
          alertKey = 'ridersError'
          break
        case 618:
          alertKey = 'restaurantClosedError'
          break
      }

      const result = await showDialogAlert({
        dialog,
        data: {
          title: t(`alertDialog.${alertKey}.title`),
          body: t(`alertDialog.${alertKey}.body`),
          btnPrimary: t(`alertDialog.${alertKey}.btnPrimary`),
          btnSecondary: t(`alertDialog.${alertKey}.btnSecondary`),
          headerImage: '/images/icons/area/sign.svg',
        },
      })

      if (result) {
        restaurantStore.closeModal()
        await restaurantStore.setArea({ area: RestaurantAreas.MOP, updateGoogleMaps: true })
        restaurantStore.openModal()
      } else {
        restaurantStore.closeModal()
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }
}

const setAddress = (addrs: Address) => {
  selectedAddress.value = addrs
}

watch(selectedAddress, async newAddrs => {
  if (newAddrs) {
    trackSelectAddress()
    await selectAddress(newAddrs)
    selectedAddress.value = undefined
  }
})
</script>

<template>
  <div class="mt-6 flex flex-col">
    <template v-for="(address, i) in latestAddresses" :key="`latest_address_${i}`">
      <div class="flex w-full cursor-pointer gap-[37px] bg-white px-4 md:px-14">
        <img
          class="hidden md:block"
          src="/images/icons/radioButton.svg"
          alt="radio button"
          @click="setAddress(address)"
        />

        <div class="flex w-full items-start gap-4 py-4" @click="setAddress(address)">
          <!-- Favorite img or previous used -->
          <img
            v-if="address.favoriteId"
            class="h-auto w-4"
            src="/images/icons/favoriteFilled.svg"
            alt="favorite icon"
          />
          <img v-else class="h-auto w-4" src="/images/icons/reorder.svg" alt="reorder icon" />

          <div>
            <!-- Alias if favorite -->
            <p
              v-if="address.favoriteId"
              class="truncate text-xs font-bold leading-4 tracking-[-0.15px]"
            >
              {{ address.alias }}
            </p>

            <!-- Address detail -->
            <div class="flex w-full flex-col text-2xs">
              <p>{{ addressStr(address) }}</p>
              <p>{{ cityStr(address) }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
